<main class="mh-100vh d-flex main-bs">
    <div class="content-main" id="content-main">
      <div class="row">
        <div class="col-md-12">
          <div class="background-color-screen rounded-16 lading-padding">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </main>
<form >
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{defaults.title}}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <i class="fa fa-close"></i>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <h1 [innerHTML]="defaults.message"></h1>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="accent" mat-flat-button [mat-dialog-close]="true"><span style="color: white"> {{'confirm' | translate}}</span></button>
    <button color="accent" mat-flat-button [mat-dialog-close]="false"><span style="color: white"> {{'no' | translate}}</span></button>
  </mat-dialog-actions>
</form>

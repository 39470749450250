<app-header></app-header>
<div (click)="goBack('home')"
	class="text-color-light montserrat cursor-pointer reservation-return">
	<img [src]="themeService.arrowReturn">
	{{'text_continue' | translate }}
</div>
<div class="text-color fw-semibold text-medium mt-24 mb-32">
	{{'text_your_cart' | translate }} ({{cart.length}} {{'text_courses' |
  translate }})
</div>
<mat-spinner *ngIf="loading"
	style="margin: 0 auto"></mat-spinner>
<div *ngIf="!loading"
	class="cart-payment-section">
	<!-- Cart -->
	<div class="background-color-trans rounded-8 padding-box width-100">
		<ng-container *ngFor="let cartItem of cart">
			<div class="course-box">
				<div class="course-box-header">
					<div class="course-box-icon">
						<img *ngIf="cartItem.details[0].course.course_type == 1"
							src="assets/icons/collectif_ski2x.png">
						<img *ngIf="cartItem.details[0].course.course_type == 2"
							src="assets/icons/prive_ski2x.png">
						<div class="course-box-title">
							<div class="text-color text-medium fw-medium">
								{{getCourseName(cartItem.details[0].course)}}
							</div>
							<span class="text-color-light text-small">
								<ng-container *ngIf="cartItem.details[0].course.course_type==1">
									{{'colective' | translate }}
								</ng-container>
								<ng-container *ngIf="cartItem.details[0].course.course_type==2">
									{{'private' | translate }}
								</ng-container>
								<ng-container *ngIf="schoolData && schoolData.sports">
									{{ getSportName(cartItem.details[0].course.sport_id) }}
								</ng-container>
							</span>
						</div>
					</div>
					<div class="course-box-icons">
						<img [src]="themeService.add"
							(click)="goTo(schoolData.slug + '/course',cartItem.courseId)"
							class="course-box-add cursor-pointer">
						<img [src]="themeService.trash"
							(click)="deleteCartItem(cartItem)"
							class="course-box-delete cursor-pointer">
					</div>
				</div>
				<div class="course-box-dates">
					<div *ngIf="cartItem.details[0].course.course_type == 1"
						class="course-content-list">
						<div *ngFor="let date of cartItem.details"
							class="text-color-light montserrat">
							· {{date.date | date: 'dd.MM.yyyy'}}
              {{date.hour_start.substring(0,5) }} -
              {{date.hour_end.substring(0,5)}}
						</div>
						<ng-container *ngIf="cartItem.details[0].extra">
							{{'text_forfait' | translate }}
							<div class="text-color">
								{{cartItem.details[0].extra.name}}
							</div>
						</ng-container>
						<div class="text-color-light montserrat mt-12">
							{{cartItem.details[0].subGroup.degree.name}}
						</div>
						<div class="text-color-light montserrat list-flex">
							<img [src]="themeService.user">
							{{cartItem.details[0].client.first_name + ' ' +
              cartItem.details[0].client.last_name}}
						</div>
					</div>
					<div *ngIf="cartItem.details[0].course.course_type == 2"
						class="course-content-list">
						<!-- Iterar sobre fechas únicas -->
						<div *ngFor="let detail of getUniqueDates(cartItem.details)"
							class="text-color-light montserrat list-flex">
							<img [src]="themeService.calendar">
							{{ detail.date | date: 'dd.MM.yyyy' }}
							<img [src]="themeService.clock">
							<span>
								{{ detail.hour_start }} - {{ detail.hour_end }}
							</span>
						</div>
						<!-- Muestra hora de inicio y fin (asumiendo que son iguales para todas las entradas) -->
						<!-- <div class="text-color-light montserrat list-flex">
              <img [src]="themeService.clock" />
              {{ cartItem.details[0].hour_start }} - {{ cartItem.details[0].hour_end }}
            </div> -->
						<div class="text-color-light montserrat pl-18">
							{{ getUniqueClients(cartItem.details).length }} {{
              'text_people_under' | translate }}
						</div>
						<!-- Iterar sobre clientes únicos -->
						<ng-container *ngIf="cartItem.details[0].extra">
							{{'text_forfait' | translate }}
							<div class="text-color">
								{{cartItem.details[0].extra.name}}
							</div>
						</ng-container>
						<div *ngFor="let client of getUniqueClients(cartItem.details)"
							class="text-color-light montserrat list-flex mt-12">
							<img [src]="themeService.user">
							{{ client.first_name + ' ' + client.last_name }}
						</div>
					</div>
					<ng-container *ngIf="cartItem.details[0].course.course_type ==2">
						<div class="text-color">
							<span class="text-color fw-semibold text-medium">
								{{
                getTotalBasePrice(cartItem.details) | number:'0.2'}}
							</span>
							{{ cartItem.details[0].course.currency }}
						</div>
					</ng-container>
					<ng-container *ngIf="cartItem.details[0].course.course_type ==1">
						<div class="text-color">
							<span class="text-color fw-semibold text-medium">
								{{(cartItem.details[0].course.is_flexible ?
                (cartItem.details.length * cartItem.details[0].price) :
                cartItem.details[0].course.price * 1) | number:'0.2'}}
							</span>
							{{ cartItem.details[0].course.currency }}
						</div>
					</ng-container>
				</div>
				<div class="width-100">
					<div class="text-color mb-8">
						{{'text_notes' | translate }}
					</div>
					<textarea rows="2"
						class="form-control width-100"></textarea>
				</div>
			</div>
			<div class="separator-line mb-32 mt-32"></div>
		</ng-container>
		<button (click)="goTo(schoolData.slug )"
			class="btn-back-grey rounded-8 button-style-icon cursor-pointer button-right"
			type="button">
			<img src="/assets/icons/icons-outline-add.png">
			{{'text_add_other_booking' | translate }}
		</button>
	</div>
	<!-- Payment -->
	<div *ngIf="cart?.length"
		class="background-color-trans rounded-8 padding-box payment-section">
		<div *ngIf="!isNanValue(this.cancellationInsurance) && this.cancellationInsurance > 0"
			class="payment-refund-check">
			<div class="text-color">
				{{'text_refund_option' | translate }}
			</div>
			<div class="selected-custom">
				<input [(ngModel)]="hasInsurance"
					(change)="updateTotal()"
					type="checkbox"
					id="insurance">
				<label for="insurance"></label>
			</div>
		</div>
		<div *ngIf="!isNanValue(this.boukiiCarePrice) && this.boukiiCarePrice > 0"
			class="payment-refund-check"
			style="margin: 10px 0 0 0">
			<div class="text-color">Boukii Care</div>
			<div class="selected-custom">
				<input [(ngModel)]="hasBoukiiCare"
					(change)="updateTotal()"
					type="checkbox"
					id="boukiiCare">
				<label for="boukiiCare"></label>
			</div>
		</div>
		<button (click)="openModalVoucher()"
			class="btn-back-primary rounded-8 button-style cursor-pointer button-center mt-24"
			type="button">
			{{'text_use_voucher' | translate }}
		</button>
		<!-- <button (click)="voucher = null"  *ngIf="voucher" class="btn-back-primary rounded-8 button-style cursor-pointer button-center mt-24" type="button">
      {{'text_use_voucher_remove' | translate }}
    </button> -->
		<div class="text-color fw-semibold mt-32">
			{{'text_you_will_pay' | translate }}
		</div>
		<div class="payment-price-flex mt-24">
			<div class="text-color uppercase">
				{{'text_subtotal' | translate }}
			</div>
			<div class="text-color min-width-price">
				<span class="text-color fw-bold">
					{{getBasePrice() | number:'0.2'}}
				</span>
				{{cart[0].details[0].course.currency}}
			</div>
		</div>
		<div *ngIf="voucher"
			class="payment-price-flex mt-8">
			<div class="text-color uppercase" style="display: flex; align-items: flex-end;">
				<img [src]="themeService.trash"
					(click)="voucher = null; vouchersApplied=[]"
					class="course-box-delete cursor-pointer">
				{{'text_voucher' | translate }}
			</div>
			<div class="text-color min-width-price">
				<span class="text-color fw-bold">
					-{{usedVoucherAmount | number:'0.2'}}
				</span>
				{{cart[0].details[0].course.currency}}
			</div>
		</div>
		<div class="payment-price-flex mt-8">
			<div *ngIf="getExtrasPrice() > 0"
				class="text-color uppercase">
				{{'FORFAIT' | translate }}
			</div>
			<div *ngIf="getExtrasPrice() > 0"
				class="text-color min-width-price">
				<span class="text-color fw-bold">
					+{{getExtrasPrice() | number:'0.2'}}
				</span>
				{{cart[0].details[0].course.currency}}
			</div>
		</div>
		<div *ngIf="hasInsurance"
			class="payment-price-flex mt-8">
			<div class="text-color uppercase">
				{{'text_refund_option' | translate }}
			</div>
			<div class="text-color min-width-price">
				<span class="text-color fw-bold">
					+{{getInsurancePrice() | number:'0.2'}}
				</span>
				{{cart[0].details[0].course.currency}}
			</div>
		</div>
		<div *ngIf="hasBoukiiCare"
			class="payment-price-flex mt-8">
			<div class="text-color uppercase">Boukii Care</div>
			<div class="text-color min-width-price">
				<span class="text-color fw-bold">
					+{{getBoukiiCarePrice() | number:'0.2'}}
				</span>
				{{cart[0].details[0].course.currency}}
			</div>
		</div>
		<div *ngIf="hasTva"
			class="payment-price-flex mt-8">
			<div class="text-color uppercase">
				{{'text_tva' | translate }}
			</div>
			<div class="text-color min-width-price">
				<span class="text-color fw-bold">
					+{{(totalNotaxes * tva) | number:'0.2'}}
				</span>
				{{cart[0].details[0].course.currency}}
			</div>
		</div>
		<div class="payment-price-flex mt-8">
			<div class="text-color uppercase">
				{{'text_total' | translate }}
			</div>
			<div class="text-color min-width-price">
				<span class="text-color fw-bold">
					{{totalPrice | number:'0.2'}}
				</span>
				{{cart[0].details[0].course.currency}}
			</div>
		</div>
		<div>
			<div class="payment-conditions mt-32">
				<div class="checkbox-custom">
					<input [(ngModel)]="conditionsAccepted"
						type="checkbox"
						id="conditions">
					<label for="conditions"
						class="checkbox-custom-label-normal text-color-light montserrat"></label>
				</div>
				<div for="conditions"
					class="checkbox-custom-label-normal text-color-light montserrat">
					{{'text_i_accept_terms' | translate }}
					<span (click)="openModalConditions()"
						class="text-color-light underline cursor-pointer">
						{{'text_booking_conditions' | translate }}
					</span>
				</div>
			</div>
			<button [disabled]="!conditionsAccepted"
				(click)="sendBooking()"
				class="btn-back-primary rounded-8 button-style cursor-pointer mt-24 width-100"
				type="button">
				{{'text_end_and_pay' | translate }}
			</button>
			<div class="text-color-light montserrat mt-12">
				{{'text_payment2' | translate }}
			</div>
			<div class="payment-title-icon mt-32 pt-12 text-color fw-semibold text-medium">
				<img src="/assets/icons/payment_landing_pink.svg">
				{{'text_payment1' | translate }}
			</div>
			<div class="text-color mt-24 montserrat text-align-center">
				{{'text_cart1' | translate }}
			</div>
			<img [src]="themeService.logoPay"
				class="payment-logo-pay mt-12 mb-12">
		</div>
	</div>
</div>
<app-modal-voucher [isOpen]="isModalVoucher"
	[schoolData]="schoolData"
	[bonuses]="vouchers"
	[appliedBonus]="vouchersApplied"
	(onClose)="closeModalVoucher($event)"></app-modal-voucher>
<app-modal-conditions *ngIf="schoolData?.slug != 'ess-charmey' && schoolData?.slug != 'ecole-suisse-de-ski'"
	[isOpen]="isModalConditions"
	[data]="'conditions_html' | translate"
	(onClose)="closeModalConditions()"></app-modal-conditions>
<app-modal-conditions *ngIf="schoolData?.slug == 'ecole-suisse-de-ski'"
	[isOpen]="isModalConditions"
	[data]="conditionsHTMLVeveyse"
	(onClose)="closeModalConditions()"></app-modal-conditions>
<app-modal-conditions *ngIf="schoolData?.slug == 'ess-charmey'"
	[isOpen]="isModalConditions"
	[data]="conditionsHTMLCharmey"
	(onClose)="closeModalConditions()"></app-modal-conditions>

<div class="header-section mb-24">
    <div class="header-left">
        <img src="assets/images/school-logo.png" class="header-left-img" />
        <div class="header-left-text">
            <div class="text-color fw-medium">{{schoolData?.data?.name}}</div>
            <span class="text-color-light text-small">{{schoolData?.data?.description}}</span>
        </div>
    </div>
    <div class="header-right">
       <!-- <img [src]="themeService.modeIcon" (click)="toggleTheme()" class="icon-mode cursor-pointer" />-->
        <div class="header-dropdown">
            <div class="text-color-light cursor-pointer header-dropdown-text" (click)="toggleDropdownLang()">
              <img [src]="selectedLangFlag" class="selected-lang-flag" style="width: 24px">
              <span>{{ selectedLang | uppercase }}</span>
                <img [src]="themeService.arrowUp"
                    [ngClass]="{'rotate-arrow': isOpenDropdownLang}" />
            </div>
            <div class="header-dropdown-content background-color-trans" *ngIf="isOpenDropdownLang">
                <div (click)="switchLang('fr')">
                    <img src="/assets/images/fr.png">
                    <span class="text-color-light" [ngClass]="{'fw-semibold': selectedLang=='fr'}">FR</span>
                </div>
                <div (click)="switchLang('en')">
                    <img src="/assets/images/en.png">
                    <span class="text-color-light" [ngClass]="{'fw-semibold': selectedLang=='en'}">EN</span>
                </div>
                <div (click)="switchLang('de')">
                    <img src="/assets/images/de.png">
                    <span class="text-color-light" [ngClass]="{'fw-semibold': selectedLang=='de'}">DE</span>
                </div>
                <div (click)="switchLang('es')">
                    <img src="/assets/images/es.png">
                    <span class="text-color-light" [ngClass]="{'fw-semibold': selectedLang=='es'}">ES</span>
                </div>
                <div (click)="switchLang('it')">
                    <img src="/assets/images/it.png">
                    <span class="text-color-light" [ngClass]="{'fw-semibold': selectedLang=='it'}">IT</span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!userLogged">
            <div (click)="openModalNewUser()" class="text-color-light cursor-pointer">
                {{'text_new_client' | translate }}
            </div>
            <button (click)="openModalLogin()" class="btn-back-primary rounded-8 button-style cursor-pointer" type="button">
                {{'text_already_client' | translate }}
            </button>
        </ng-container>

        <ng-container *ngIf="userLogged">
            <div class="header-dropdown">
                <div class="text-color-light cursor-pointer header-dropdown-text" (click)="toggleDropdownUser()">
                  {{userLogged.clients[0].last_name}}
                    <img [src]="themeService.arrowUp"
                        [ngClass]="{'rotate-arrow': isOpenDropdownUser}" />
                </div>
                <div class="header-dropdown-content background-color-trans" *ngIf="isOpenDropdownUser">
                    <div (click)="goBack('/user')" class="text-color-light cursor-pointer">
                        Profil
                    </div>
                    <div class="text-color-light cursor-pointer" (click)="logOut()">
                        Déconnexion
                    </div>
                </div>
            </div>

            <div (click)="goTo(schoolData?.data?.slug + '/cart')" class="header-cart cursor-pointer">
                <img [src]="themeService.cart" class="header-cart-img">
                <div class="header-cart-number" *ngIf="cart">{{calculateCartLength()}}</div>
            </div>
        </ng-container>
    </div>
</div>

<app-modal-login [isOpen]="isModalLogin" (onClose)="closeModalLogin()"></app-modal-login>
<app-modal-new-user [isOpen]="isModalNewUser" (onClose)="closeModalNewUser()"></app-modal-new-user>

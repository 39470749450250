<form >
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{'add_user' | translate }}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <i class="fa fa-close"></i>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col" *ngIf="!loading">

    <div [formGroup]="form" style="margin: 5% 0 0 0">
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field class="flex-auto">
          <mat-label>{{'name' | translate }}</mat-label>
          <input formControlName="name" matInput required type="text" [(ngModel)]="defaults.first_name">

        </mat-form-field>

        <mat-form-field class="flex-auto">
          <mat-label>{{'surname' | translate }}</mat-label>
          <input formControlName="surname" matInput required type="text" [(ngModel)]="defaults.last_name">
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 3% 0 0 0;">
        <mat-form-field class="flex-auto">
          <input matInput [matDatepicker]="pickerFrom" placeholder="{{'birth_date' | translate }}" formControlName="fromDate" [max]="minDate" [(ngModel)]="defaults.birth_date">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom [startAt]="minDate"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>{{'langs' | translate }}</mat-label>
          <mat-select [formControl]="languagesControl" multiple>
            <mat-select-trigger>
              <div *ngIf="selectedLanguages.length > 0; else placeholderLangs">
                {{ getSelectedLanguageNames() }}
              </div>
              <ng-template #placeholderLangs>{{'choose_langs' | translate }}</ng-template>
            </mat-select-trigger>
            <mat-option *ngFor="let language of filteredLanguages | async" [value]="language" (onSelectionChange)="toggleSelectionLanguages(language)">
              {{language.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
   </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end"  *ngIf="!loading">
    <button color="accent" mat-flat-button [disabled]="form.invalid" (click)="save()">{{'confirm' | translate}}</button>
    <button color="accent" mat-flat-button [mat-dialog-close]="false">{{'no' | translate}}</button>
  </mat-dialog-actions>
</form>

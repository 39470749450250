<div class="modal" [@fadeInOut] *ngIf="isOpen" (click)="closeModal()">
    <div class="modal-content background-color-full rounded-8" (click)="$event.stopPropagation()">
        <div class="text-color fw-semibold text-semibig modal-flex-title mb-32 pb-12">
            {{'text_use_voucher' | translate }}
            <img [src]="themeService.close" class="cursor-pointer" (click)="closeModal()" />
        </div>

        <div class="modal-form">

          <mat-radio-group>
            <ng-container *ngFor="let b of bonuses">
              <mat-radio-button *ngIf="!b.payed " [disabled]="isInUse(b.id)" class="radio"  (change)="bonus = b" [value]="b">
                {{ b.code }} - {{'remaining_balance' | translate}}: {{ b.remaining_balance  | number:'0.2'}} CHF <span style="color:#8fd14f">{{ isInUse(b.id) ? 'applied' : '' | translate}}</span>
              </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>

        <button (click)="closeModal()" class="btn-back-primary rounded-8 button-style cursor-pointer mt-32 width-100" type="button">
            {{'text_confirm' | translate }}
        </button>

    </div>
  </div>

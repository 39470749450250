import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-modal-conditions',
  templateUrl: './modal-conditions.component.html',
  styleUrls: ['./modal-conditions.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class ModalConditionsComponent implements OnInit {

  @Input() isOpen: boolean = false;
  @Input() data: string = '';
  @Output() onClose = new EventEmitter<void>();

  constructor(public themeService: ThemeService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.onClose.emit();
  }

}
